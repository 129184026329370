<template>
  <div class="analysis__search">
    <img src="@/assets/img/search.svg" alt="search" />
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Поиск по анализам..."
      @input="searchAnalyses"
    />
  </div>
  <div class="container">
    <template v-if="filteredAnalyses.length === 0">
      <SearchStub />
    </template>
    <template v-else>
      <h1 class="header">
        {{ headerText }}
      </h1>
      <div class="analysis-list">
        <AnalysisItem
          v-for="analysis in filteredAnalyses"
          :key="analysis.Code + '-' + analysis.Analysis"
          :analysis="analysis"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import AnalysisItem from "@/components/AnalysisItem.vue";
import Fuse from "fuse.js";
import SearchStub from "../components/SearchStub.vue";

export default {
  name: "AnalysisList",
  components: {
    AnalysisItem,
    SearchStub,
  },
  setup() {
    const analyses = ref([]);
    const filteredAnalyses = ref([]);
    const searchQuery = ref("");
    let fuse;

    const headerText = computed(() => {
      return searchQuery.value.trim() === ""
        ? "Популярные анализы"
        : "Найденные анализы";
    });

    onMounted(async () => {
      try {
        const response = await fetch("/analysis_list.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        analyses.value = data;
        filteredAnalyses.value = data;

        fuse = new Fuse(data, {
          keys: ["Analysis"],
          threshold: 0.5,
          findAllMatches: true,
          shouldSort: true,
        });

        const fromSearch = localStorage.getItem("fromSearch");
        if (fromSearch === "true") {
          const savedQuery = localStorage.getItem("searchQuery");
          if (savedQuery) {
            searchQuery.value = savedQuery;
            searchAnalyses();
            localStorage.removeItem("fromSearch");
          }
        }
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      }
    });

    const searchAnalyses = () => {
      if (searchQuery.value.trim() === "") {
        filteredAnalyses.value = analyses.value;
      } else {
        const result = fuse.search(searchQuery.value);
        filteredAnalyses.value = result.map(({ item }) => item);
      }
    };

    watch(searchQuery, searchAnalyses);

    return {
      analyses,
      filteredAnalyses,
      searchQuery,
      searchAnalyses,
      headerText,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  transition: opacity 0.5s ease;
  color: #000027;
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.64px;
  margin-bottom: 32px;

  @media (max-width: 576px) {
    font-size: 24px;
    margin-bottom: 24px;
  }
}
.header-enter-active,
.header-leave-active {
  transition: opacity 0.5s ease;
}
.header-enter-from,
.header-leave-to {
  opacity: 0;
}
.header-enter-to,
.header-leave-from {
  opacity: 1;
}

.analysis__search {
  display: flex;
  flex-flow: nowrap row;
  gap: 8px;
  padding: 80px 64px 0px 64px;
  background: #ecf1f6;

  @media (max-width: 768px) {
    padding: 80px 32px 0px 32px;
  }

  @media (max-width: 576px) {
    align-items: center;
    padding: 56px 16px 0px 16px;
  }

  input {
    width: 100%;
    display: flex;
    padding: 20px 0 22px 0;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;

    @media (max-width: 576px) {
      padding: 16px 0;
    }
  }

  img {
    @media (max-width: 576px) {
      height: 18px;
    }
  }

  input[type="text"] {
    color: #000027;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;

    @media (max-width: 576px) {
      font-size: 16px;
    }

    &::placeholder {
      color: #8e91a3;
    }
  }
}

.container {
  padding-top: 40px;
}

.analysis-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 104px;
}
</style>
