<template>
  <div class="search__stub-section">
    <h2>
      Поиск не&nbsp;дал результатов,<br />попробуйте изменить параметры поиска
    </h2>
    <p>
      Если у&nbsp;вас появились вопросы,<br />позвоните нам по&nbsp;телефону:
      <br /><br />+7 342 414-88-28
    </p>
  </div>
</template>

<style lang="scss" scoped>
.search__stub-section {
  display: flex;
  flex-flow: nowrap column;
  height: calc(100vh - 220px);

  h2 {
    color: #000027;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.64px;

    @media (max-width: 576px) {
      font-size: 24px;
    }
  }

  p {
    margin-top: auto;
    opacity: 0.4;
    color: #000027;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}
</style>
